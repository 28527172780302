import { Link } from "gatsby";
import * as React from "react";

interface HeaderProps {
  siteName: string;
  tags: string;
}

export default class Header extends React.Component<HeaderProps> {
  public render() {
    return (
      <div className="w-full bg-gray-300">
        <div className="w-full container mx-auto">
          <span className="font-sans text-4xl text-left font-bold">
            <Link to="/">{this.props.siteName}</Link>
          </span>
          <br />
          <span className="font-sans text-left p-1">{this.props.tags}</span>
        </div>
      </div>
    );
  }
}
