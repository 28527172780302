import * as React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import HeadlineWidget from "../components/headline-widget";
import { LayoutParams } from "../gwangju-types";
import Advertisement from "../components/advertisement";
import { Helmet } from "react-helmet";
import LatestPostsWidget from "../components/latest-posts-widget";

interface LayoutProps {
  layoutParams: LayoutParams;
  contentsTitle?: string;
}

export default class Layout extends React.Component<LayoutProps> {
  public render() {
    let title = this.props.layoutParams.title;
    if (this.props.contentsTitle != undefined) {
      title = this.props.contentsTitle + " - " + this.props.layoutParams.title;
    }

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          flexGrow: 1,
        }}
        className="w-full h-screen layout-root"
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="flex flex-col min-h-screen">
          <div className="w-full">
            <Header
              siteName={this.props.layoutParams.title}
              tags={this.props.layoutParams.tags}
            />
          </div>
          <div className="flex-grow">
            <div className="container mx-auto">
              <div className="flex flex-row flex-wrap w-full">
                <div className="w-full lg:w-3/4">
                  <Advertisement
                    client={this.props.layoutParams.adClient}
                    slot="4491040390"
                    width="728px"
                    height="90px"
                  />
                  {this.props.children}
                  <Advertisement
                    client={this.props.layoutParams.adClient}
                    slot="2097832845"
                    width="728px"
                    height="90px"
                  />
                </div>
                <div className="w-full lg:w-1/4">
                  <HeadlineWidget
                    headlines={this.props.layoutParams.headlines}
                  />
                  <LatestPostsWidget
                    latestPosts={this.props.layoutParams.latestPosts}
                  />
                  <Advertisement
                    client={this.props.layoutParams.adClient}
                    slot="7044023874"
                    width="300px"
                    height="250px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
