import * as React from "react";
import { navigate } from "gatsby";

interface NonPreloadLinkProps {
  url: string;
}

export default class NonPreloadLink extends React.Component<NonPreloadLinkProps> {
  public render() {
    return (
      <a
        href={this.props.url}
        onClick={(event) => {
          event.preventDefault();
          navigate(this.props.url);
        }}
      >
        {this.props.children}
      </a>
    );
  }
}
