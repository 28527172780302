import * as React from "react";

export default class Footer extends React.Component {
  public render() {
    return (
      <div className="w-full bg-gray-300 ">
        <footer>
          <div className="w-full container mx-auto pt-2 pb-2">
            <span>
              이 블로그는 개인 블로그입니다. 게시글은 오류를 포함하고 있을 수
              있지만, 저자는 오류를 해결하기 위해 노력하고 있습니다.
            </span>
            <br />
            <span>
              게시글에 별도의 고지가 없는 경우, 크리에이티브 커먼즈
              저작자표시-비영리-변경금지 4.0 라이선스를 따릅니다.
            </span>
            <br />
            <br />
            <span>
              This blog is personal blog. published posts may contain some
              errors, but author doing efforts to clear errors.
            </span>
            <br />
            <span>
              If post have not notice of license, it under creative commons
              Attribution-NonCommercial-NoDerivatives 4.0.
            </span>
          </div>
        </footer>
      </div>
    );
  }
}
