import * as React from "react";
import { TitleUuidPair } from "../gwangju-types";
import NonPreloadLink from "../components/non-preload-link";

interface HeadlineWidgetProps {
  headlines: TitleUuidPair[];
}

export default class HeadlineWidget extends React.Component<HeadlineWidgetProps> {
  public render() {
    const mapHeadlineElements = (data: TitleUuidPair[]) => {
      return data.map((headlinePair, i) => {
        return (
          <li key={i} className="hover:text-blue-500 p-1">
            <NonPreloadLink url={"/posts/" + headlinePair.uuid}>
              {headlinePair.title}
            </NonPreloadLink>
          </li>
        );
      });
    };

    return (
      <div className="box-content w-full">
        <br />
        <div className="w-full bg-gray-100 font-semi-bold text-2xl p-2">
          Headlines
        </div>
        <div className="w-full pl-5">
          <ul>{mapHeadlineElements(this.props.headlines)}</ul>
        </div>
      </div>
    );
  }
}
