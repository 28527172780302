import * as React from "react";
import { Helmet } from "react-helmet";
import { CSSProperties } from "react";

interface AdvertisementProps {
  client: string;
  slot: string;
  width: string;
  height: string;
}

declare global {
  interface Window {
    adsbygoogle: { [key: string]: unknown }[];
  }
}

export default class Advertisement extends React.Component<AdvertisementProps> {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  public render() {
    const width = this.props.width;
    const height = this.props.height;
    const cssProperty: CSSProperties = {
      display: "inline-block",
      width: width,
      height: height,
    };

    return (
      <div className="text-center p-2" key={Math.random()}>
        <Helmet>
          <script
            crossOrigin="anonymous"
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          ></script>
        </Helmet>
        <div>
          <ins
            className="adsbygoogle"
            style={cssProperty}
            data-ad-client={this.props.client}
            data-ad-slot={this.props.slot}
          ></ins>
        </div>
      </div>
    );
  }
}
